import captureSentryError from '@/utils/CaptureSentryError.js'
import integrations from '@/third-party-integrations/integrations.js'
import sdk from '@megaport/api-sdk'

// Initial state
const coreState = {
  usersReady: false,
  users: [],
}

const getters = {
  getUser: state => employmentId => {
    return state.users.find(user => user.employmentId === employmentId)
  },
  getUserByPersonUid: state => personUid => {
    return state.users.find(user => user.personUid === personUid)
  },
}

const actions = {
  async syncUsers(context) {
    try {
      const response = await sdk.instance.employment().get()
      response.sort((a, b) => {
        const compareArgs = [undefined, { sensitivity: 'base' }]
        // Sort by first name, then last name, then email
        let result = a.firstName.localeCompare(b.firstName, ...compareArgs)
        if (result === 0) result = a.lastName.localeCompare(b.lastName, ...compareArgs)
        if (result === 0) result = a.email.localeCompare(b.email, ...compareArgs)
        return result
      })
      context.commit('setUsers', response)
    } catch (error) {
      captureSentryError(error)
      throw error
    } finally {
      context.commit('setUsersReady', true)
    }
  },
  async postUser(context, payload) {
    if (payload.personId) {
      try {
        await sdk.instance.employee(payload.personId).update(payload)
        await context.dispatch('syncUsers')
        context.commit(
          'Notifications/notifyGood',
          {
            title: window.mpApp.$t('general.success-updating', { thing: window.mpApp.$t('general.user') }),
            message: window.mpApp.$t('general.updated-thing', { thing: `${payload.firstName} ${payload.lastName}` }),
          },
          {
            root: true,
          }
        )
      } catch (error) {
        context.commit(
          'Notifications/notifyBad',
          {
            title: window.mpApp.$t('general.error-updating', { thing: window.mpApp.$t('general.user') }),
            message: error.data.message,
          },
          {
            root: true,
          }
        )
        throw error
      }
    } else {
      try {
        await sdk.instance.employment().employ(payload)
        await context.dispatch('syncUsers')
        context.commit(
          'Notifications/notifyGood',
          {
            title: window.mpApp.$t('general.success-creating', { thing: window.mpApp.$t('general.user') }),
            message: window.mpApp.$t('general.created-thing', { thing: `${payload.firstName} ${payload.lastName}` }),
          },
          {
            root: true,
          }
        )
      } catch (error) {
        if (!error.data) {
          integrations.sentry.captureException(new Error('Failed to create employee'), {
            contexts: {
              company: {
                payload,
                error,
                'X-Call-Context': context.rootGetters['ApplicationContext/companyUid'],
              },
            },
          })
        }
        window.mpApp.$notify({
          title: window.mpApp.$t('general.error-creating', { thing: window.mpApp.$t('general.user') }),
          message: error.data?.message ?? window.mpApp.$t('general.no-info'),
          type: 'error',
          duration: 6000,
        })
        throw error
      }
    }
  },
  async deleteUser(context, payload) {
    // TODO: Do we actually need this check?
    if (!payload.personId) return

    try {
      await sdk.instance.employee(payload.personId).delete()
      await context.dispatch('syncUsers')
      context.commit(
        'Notifications/notifyGood',
        {
          title: window.mpApp.$t('general.success-deleting', { thing: window.mpApp.$t('general.user') }),
          message: window.mpApp.$t('general.deleted-thing', { thing: `${payload.firstName} ${payload.lastName}` }),
        },
        { root: true }
      )
    } catch (error) {
      context.commit(
        'Notifications/notifyBad',
        {
          title: window.mpApp.$t('general.error-deleting', { thing: window.mpApp.$t('general.user') }),
          message: error.data.message,
        },
        { root: true }
      )
      throw error
    }
  },
  updateLoggingServices(_context, payload) {
    // Set user information in third party integrations on login
    if (payload.status === 'login') {
      // Sentry
      integrations.sentry.setUser(payload)
      // PostHog
      integrations.postHog.setUser(payload)
      // Segment
      integrations.segment.setUser(payload)
      integrations.segment.trackEvent('login')

    // Clear user information on logout
    } else if (payload.status === 'logout') {
      // Sentry
      integrations.sentry.clearUser()
      // PostHog
      integrations.postHog.clearUser()
      // Segment
      integrations.segment.clearUser()
    }
  },
}

const mutations = {
  logout(state) {
    state.users = []

    sdk.instance.effectiveUid = null
  },
  setUsers(state, payload) {
    state.users = payload
  },
  setUsersReady(state, tf) {
    state.usersReady = tf
  },
}

export default {
  namespaced: true,
  state: coreState,
  getters,
  actions,
  mutations,
}
