import { PUBLIC_COMPANY_UID } from '@/Globals.js'
import captureSentryError from '@/utils/CaptureSentryError.js'
import sdk from '@megaport/api-sdk'

// Initial state
const coreState = {
  data: {
    companyUid: null,
    markets: [],
    techSupportEmail: null,
    techSupportPhone: null,
  },
  partnerBranding: null,
  loading: false,
  companyReady: false,
}

const coreGetters = {
  /**
   * They are not deemed to have a company if there is no id or the id is
   * the default company or the trading name is blank.
   */
  hasCompany: state => {
    const isNotPublicCompany = (state.data.companyUid !== null && state.data.companyUid !== PUBLIC_COMPANY_UID)
    const isNotBlankTradingName = (state.data.tradingName !== null && state.data.tradingName !== '')

    return isNotPublicCompany && isNotBlankTradingName
  },
  companySupplyRegions: state => {
    return state.data.supplies ? state.data.supplies.map(s => s.region) || [] : []
  },
  companyId: state => {
    return state.data.companyId
  },
  companyUid: (state, getters, rootState) => state.data.companyUid || rootState.Auth.data.companyUid,
  techSupportDetails: state => {
    return {
      techSupportEmail: state.data.techSupportEmail ?? null,
      techSupportPhone: state.data.techSupportPhone ?? null,
    }
  },
  megaportContactDetails: state => ({
    accountManager: {
      name: state.data.accountManager?.name ?? null,
      email: state.data.accountManager?.email ?? null,
      active: state.data.accountManager?.active ?? false,
    },
    customerSuccessManager: {
      name: state.data.customerSuccessManager?.name ?? null,
      email: state.data.customerSuccessManager?.email ?? null,
      active: state.data.customerSuccessManager?.active ?? false,
    },
  }),
}

const actions = {
  /**
   * Refresh the local data with the data from the API
   *
   * @param {object} context (store context)
   */
  syncCompany(context) {
    if (!context.rootState.Auth.data.accessToken && !context.rootState.Auth.data.session) {
      return
    }
    context.commit('setLoading', true)
    sdk.instance
      .company()
      .get()
      .then(res => {
        context.commit('setCompany', res)
        if (!context.getters.hasCompany) {
          context.commit('setTradingName', null)
        }
      })
      .catch(e => {
        // TODO: Improve error processing
        console.warn(e)
      })
      .finally(() => {
        context.commit('setLoading', false)
      })
  },
  /**
   * Update current company
   * @param {object} context (store context)
   * @param {*} payload - the company data to store
   */
  async updateCompany(context, payload) {
    context.commit('setLoading', true)
    try {
      await sdk.instance.company().update(payload)
      context.commit('updateCompany', payload)
    } catch (error) {
      captureSentryError(error)
      return Promise.reject(error)
    } finally {
      context.commit('setLoading', false)
    }
  },
  /**
   * Update the company data via the API and mutate the
   * @param {object} context (store context)
   * @param {*} payload - the company data to store
   */
  postCompany(context, payload) {
    return new Promise((resolve, reject) => {
      context.commit('setLoading', true)
      const accessToken = context.rootGetters['Auth/accessToken']
      // If the user's company is the default company, then upgrade them
      // to an actual company, and store the data in the local store.
      if (context.rootState.Auth.data.companyUid === PUBLIC_COMPANY_UID) {
        sdk.instance
          .company()
          .upgrade({
            tradingName: payload.tradingName,
          })
          .then(res => {

            context.commit(
              'Notifications/notifyGood',
              {
                title: window.mpApp.$t('general.success-creating', { thing: window.mpApp.$t('menu.company-profile') }),
                message: window.mpApp.$t('authentication.registered-company', { name: res.legalName }),
              },
              {
                root: true,
              }
            )

            // move cart content to the new companyUid
            context.dispatch('Services/moveCartContents', res.companyUid, { root: true })
            // We need to force the user to logout as a new JWT token has been generated with the new company
            context.dispatch('Auth/logout', null, { root: true })
            return resolve()
          })
          .catch(err => {
            console.error(err)
            // TODO: Improve error processing
            if (err.data?.message) {
              context.commit(
                'Notifications/notifyBad',
                {
                  title: window.mpApp.$t('general.error-creating', { thing: window.mpApp.$t('menu.company-profile') }),
                  message: err.data.message,
                },
                {
                  root: true,
                }
              )
            }

            return reject(err)
          })
      } else {
        sdk.instance
          .company()
          .update({
            tradingName: payload.tradingName,
          })
          .then(() => {
            context.commit('setCompany', payload)
            context.dispatch('Auth/login', { payload: { accessToken } }, { root: true }).then(() => {
              context.commit(
                'Notifications/notifyGood',
                {
                  title: window.mpApp.$t('general.success-updating', { thing: window.mpApp.$t('menu.company-profile') }),
                  message: window.mpApp.$t('authentication.updated-company', { name: payload.tradingName }),
                },
                {
                  root: true,
                }
              )

              return resolve()
            })
          })
          .catch(err => {
            // Only show an error if the error is unhandled
            if (!err.handled) {
              context.commit(
                'Notifications/notifyBad',
                {
                  title: window.mpApp.$t('general.error-updating', { thing: window.mpApp.$t('menu.company-profile') }),
                  message: err.data.message,
                },
                {
                  root: true,
                }
              )
            }
            return reject(err)
          })
      }
    })
  },
  /**
   * Update the company Tech Support Details via the API and mutate the
   * @param {object} context (store context)
   * @param {*} payload - the company data to store
   */
  updateTechSupportDetails(context, payload) {
    return new Promise((resolve, reject) => {
      context.commit('setLoading', true)
      sdk.instance
        .company()
        .update({
          techSupportEmail: payload.techSupportEmail,
          techSupportPhone: payload.techSupportPhone,
        })
        .then(() => {
          context.commit('updateCompany', payload)
          return resolve()
        })
        .catch(err => {
          return reject(err)
        })
        .finally(() => {
          context.commit('setLoading', false)
        })
    })
  },
  async loadPartnerBrandingProfile(context) {
    context.commit('setLoading', true)
    try {
      const data = await sdk.instance
        .company()
        .getPartnerBrandingProfile()

      context.commit('setPartnerBranding', data)
    } catch (error) {
      captureSentryError(error)
      throw error
    } finally {
      context.commit('setLoading', false)
    }
  },

  clearPartnerBranding(context) {
    context.commit('setPartnerBranding', null)
  },
}

const mutations = {
  setCompany(state, payload) {
    state.data = { ...payload }
    state.loading = false
  },
  updateCompany(state, payload) {
    state.data = { ...state.data, ...payload }
  },
  setTradingName(state, tradingName) {
    state.data.tradingName = tradingName
  },
  logout(state) {
    state.data = { companyUid: null }
  },
  setLoading(state, isLoading) {
    state.loading = isLoading

    if (!isLoading) {
      state.companyReady = true
    }
  },
  setPartnerBranding(state, result) {
    state.partnerBranding = result
  },
}

export default {
  namespaced: true,
  state: coreState,
  getters: coreGetters,
  actions,
  mutations,
}
