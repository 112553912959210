import captureSentryError from '@/utils/CaptureSentryError.js'

// onLogout is registered at the top level so that it can easily clean up
// the entire data tree.
const onLogout = async context => {
  await context.commit('Auth/logout')
  context.commit('Company/logout')
  context.commit('Marketplace/logout')
  context.commit('Markets/logout')
  context.commit('Notifications/logout')
  context.dispatch('ServiceFilters/logout')
  context.commit('Services/logout')
  context.dispatch('ServiceStatus/logout')
  context.commit('UserNotifications/logout')
  context.commit('UserProfile/logout')
  context.commit('Users/logout')
  context.commit('ApplicationContext/logout')
  context.commit('MVE/logout')
}

const onLogin = async context => {
  // Note: services are loaded as part of the login calls so we know
  // where to redirect people to.
  const isManagedContext = context.getters['ApplicationContext/isManagedContext']
  const isAdminContext = context.getters['ApplicationContext/isAdminContext']
  const rootAccountType = context.getters['Auth/rootAccountType']
  const hasPartnerBrandingFeatureEnabled = context.rootGetters['Auth/hasFeatureFlag']('partner_co_branding_enabled')
  const isPartnerBrandingEnabled = hasPartnerBrandingFeatureEnabled && context.rootGetters['Auth/isPartnerBrandingEnabled']

  // These actions should be completed before the user is marked as login, to minimize flashing when loading into the portal.
  const beforeLoginActions = [
    // Load the partner branding data for PARTNER accounts
    ...(!isManagedContext && rootAccountType === 'PARTNER' && isPartnerBrandingEnabled
      ? [context.dispatch('Company/loadPartnerBrandingProfile')]
      : []),

    context.dispatch('Services/loadLocations'),
    // Fetch transit-enabled markets for Ports and MCRs (MVE is transit-enabled in all markets offering MVEs)
    context.dispatch('Services/fetchTransitEnabledMarkets'),
    // We need to load the marketplace profiles before we can load the partner ports
    // as the partner ports function handles calculating and setting the friendly marketplace title.
    // Let's not do this for Portal v7 please 😒
    context
      .dispatch('Marketplace/loadMarketplaceProfiles')
      .then(async () => {
        await context.dispatch('Services/loadPartnerPorts')
      })
      .catch(e => {
        captureSentryError(e)
      }),
  ]

  if (!isAdminContext && !isManagedContext) {
    // This is assumed to be loaded by the services store in many places, so needs to be loaded before services.
    beforeLoginActions.push(context.dispatch('Company/syncCompany'))
  }

  await Promise.all(beforeLoginActions)

  // Background Actions, lesser priority, probably should be fetched on demand instead of on login, but here we are.
  context.dispatch('ServiceStatus/fetchEnsEvents')
  context.dispatch('Services/cartsFromServer')
  context.dispatch('UserNotifications/loadPreferences')
  context.dispatch('Markets/syncMarkets')
  context.dispatch('Users/syncUsers')
  context
    .dispatch('Markets/syncSupplies')
    .then(() => { })
    .catch(e => {
      // This can fail because you don't have the "marketing" permission, so we'll catch
      // that but for anything else, rethrow it to be picked up by sentry
      if (e.status !== 403) {
        captureSentryError(e)
      }
    })


  if (!isManagedContext && !isAdminContext) {
    context.dispatch('UserProfile/syncProfile')
  }

  context.commit('MegaportCloudRouter/populatePayloadSchema')
}


// Actions to fire off during initial setup - mainly data that doesn't change often.
const onInit = context => {
  context.dispatch('IXTypes/refreshIxTypes')
}

export default {
  onLogout,
  onLogin,
  onInit,
}
